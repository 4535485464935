import Home from './Pages/Home';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
